<template>
  <ui-component-modal
    modalTitle="Edit location rights"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveOptionEditSettings"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table class="table is-fullwidth">
        <tbody>
          <tr>
            <td>Channels</td>
            <td width="50">
              <div class="tabs is-toggle is-small">
                <ul>
                  <li
                    :class="{ 'is-active': mOption.EditSettings.EditChannels }"
                  >
                    <a @click="mOption.EditSettings.EditChannels = true">
                      <span>Yes</span>
                    </a>
                  </li>
                  <li
                    :class="{ 'is-active': !mOption.EditSettings.EditChannels }"
                  >
                    <a @click="mOption.EditSettings.EditChannels = false">
                      <span>No</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>Image</td>
            <td width="50">
              <div class="tabs is-toggle is-small">
                <ul>
                  <li :class="{ 'is-active': mOption.EditSettings.EditImage }">
                    <a @click="mOption.EditSettings.EditImage = true">
                      <span>Yes</span>
                    </a>
                  </li>
                  <li :class="{ 'is-active': !mOption.EditSettings.EditImage }">
                    <a @click="mOption.EditSettings.EditImage = false">
                      <span>No</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>Meeting types</td>
            <td width="50">
              <div class="tabs is-toggle is-small">
                <ul>
                  <li
                    :class="{
                      'is-active': mOption.EditSettings.EditMeetingtypes,
                    }"
                  >
                    <a @click="mOption.EditSettings.EditMeetingtypes = true">
                      <span>Yes</span>
                    </a>
                  </li>
                  <li
                    :class="{
                      'is-active': !mOption.EditSettings.EditMeetingtypes,
                    }"
                  >
                    <a @click="mOption.EditSettings.EditMeetingtypes = false">
                      <span>No</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>Prices</td>
            <td width="50">
              <div class="tabs is-toggle is-small">
                <ul>
                  <li :class="{ 'is-active': mOption.EditSettings.EditPrice }">
                    <a @click="mOption.EditSettings.EditPrice = true">
                      <span>Yes</span>
                    </a>
                  </li>
                  <li :class="{ 'is-active': !mOption.EditSettings.EditPrice }">
                    <a @click="mOption.EditSettings.EditPrice = false">
                      <span>No</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>Texts</td>
            <td width="50">
              <div class="tabs is-toggle is-small">
                <ul>
                  <li :class="{ 'is-active': mOption.EditSettings.EditText }">
                    <a @click="mOption.EditSettings.EditText = true">
                      <span>Yes</span>
                    </a>
                  </li>
                  <li :class="{ 'is-active': !mOption.EditSettings.EditText }">
                    <a @click="mOption.EditSettings.EditText = false">
                      <span>No</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>Features</td>
            <td width="50">
              <div class="tabs is-toggle is-small">
                <ul>
                  <li
                    :class="{ 'is-active': mOption.EditSettings.EditFeatures }"
                  >
                    <a @click="mOption.EditSettings.EditFeatures = true">
                      <span>Yes</span>
                    </a>
                  </li>
                  <li
                    :class="{ 'is-active': !mOption.EditSettings.EditFeatures }"
                  >
                    <a @click="mOption.EditSettings.EditFeatures = false">
                      <span>No</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import optionProvider from '@/providers/option'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mOption: null,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['locationState']),
    ...mapState('optionStore', ['option']),
  },

  created() {
    this.mOption = JSON.parse(JSON.stringify(this.option))
  },

  methods: {
    ...mapMutations('optionStore', ['setOption']),

    saveOptionEditSettings() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        optionProvider.methods
          .saveLocationEditSettings(self.option.Id, self.mOption.EditSettings)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true

              self.setOption(response.data)

              self.onClickCancel()
            }
          })
          .catch((e) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
